import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'kenteken', pathMatch: 'full' },
  { path: 'kenteken', loadChildren: './kenteken/kenteken.module#KentekenPageModule' },
  { path: 'search-element', loadChildren: './kenteken/search/search-element/search-element.module#SearchElementPageModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
